import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import _ from 'lodash';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { FlexContainer, Heading, LinkRow, SystemsCell, SystemsContainer } from '../components/Notes/LinkRow';
import { slugToTitle } from '../../utils/slug';
import { sortNodesBySystem, sortNodesByYear, sortNodesIndex } from '../utility/sort';
import Subheader from '../components/Subheader';
import { getIconSrc } from '../utility/icons';
import { getTheme } from '../utility/theme';
import { generateSection, StickyTop } from '../templates/directory';
import { Abbrev, getSystemsAbbreviation } from '../components/Notes/RowReveal';
import { Box, Button } from '@material-ui/core';

const Container = styled.span`
  margin-right: 0.4rem;
  display: flex;
  align-content: flex-start;

  flex-shrink: 0; /* prevent icons from narrowing */
  img {
    background: none;
    height: 1rem;
  }
  > p {
    margin-bottom: 0;
    background: pink;
  }
`;
const getIcon = (type: string): JSX.Element => {
  const theme = getTheme();
  const src = getIconSrc(type, theme);
  return (
    <Container>
      <img src={src} />
    </Container>
  );
};

const ButtonContainer = styled.div`
  button {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const Directory = (props: any) => {
  const base = '/';
  const CURRENT_DIR = props.uri.slice(base.length);

  const data = useStaticQuery(graphql`
    query QueryHome {
      allDirectory {
        nodes {
          absolutePath
          relativePath
          relativeDirectory
          dir
          name
          fields {
            slug
            relativeDirectorySlug
            type
            name
          }
        }
      }
      allFile {
        nodes {
          absolutePath
          dir
          relativeDirectory
          relativePath
          name
          publicURL
          fields {
            slug
            relativeDirectorySlug
            type
            name
            display
            year
            significance
            title
            note
            journalYear
          }
        }
      }
    }
  `);
  const raw = [...data.allDirectory.nodes];
  const filtered = raw.filter(r => r.fields && r.fields.relativeDirectorySlug === CURRENT_DIR).sort(sortNodesIndex);

  const landmark = data.allFile.nodes.filter(n => n.fields.significance === 'Landmark').sort(sortNodesByYear);
  const important = data.allFile.nodes.filter(n => n.fields.significance === 'Important').sort(sortNodesByYear);

  const [showImportant, setShowImportant] = useState(false);
  const handleClickImportant = () => {
    setShowImportant(!showImportant);
  }

  const [showAll, setShowAll] = useState(false);
  const [dataLandmark, setDataLandmark] = useState(landmark);

  const sortBySystem = () => {
    const sorted = landmark.sort(sortNodesBySystem);
    setDataLandmark(sorted);
  }

  const sortByYear = () => {
    const sorted = landmark.sort(sortNodesByYear);
    setDataLandmark(sorted);
  }

  useEffect(() => {
    function onKeyPress(e) {
      if (e.key === 'e') {
        setShowAll(!showAll)
      }
    }
    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keydown', onKeyPress);
    }
  });

  return (
    <Layout>
      <SEO title="Notes" />
      <Subheader crumbs={props.pageContext.crumbs} />
      <StickyTop>
        +
      </StickyTop>

      <h2>systems</h2>
      <SystemsContainer>
        {filtered.map(r => {
          const { name, type } = r.fields;
          const abbrev = getSystemsAbbreviation(r.fields.name);
          const display = slugToTitle(name);
          const Icon = getIcon(type);
          return (
            <Link to={r.fields.slug} key={display}>
              <Abbrev color={abbrev.color}>
                {abbrev.short}
              </Abbrev>
            </Link>
          );
        })}
      </SystemsContainer>

      <Heading>
        <h2>All Landmark Papers</h2>
      </Heading>

      <ButtonContainer>
        <Button variant="contained" color="primary" onClick={sortBySystem}
          size="small">
            By System
        </Button>
        <Button variant="contained" color="primary" onClick={sortByYear}
          size="small">
            By Year
        </Button>
      </ButtonContainer>

      <FlexContainer>
        {generateSection(dataLandmark, showAll)}
      </FlexContainer>

      <Heading onClick={handleClickImportant}>
        <h2>All Important Papers</h2>
      </Heading>
      {showImportant && generateSection(important, showAll)}
    </Layout>
  );
};

export default Directory;
